import { CapacityInput, EngineInput, FieldInput, GetMakers, GetTripDetails, InputMaybe } from 'services/graphql';
import { GetBoatDetailsData } from 'services/types';
import { momentToDate } from './dates';
import { EditableCustomFields } from 'services/types/fields';

/** Dynamic Inputs */

type InputFieldType =
  | ({
      name: string;
      value?: string | null;
    } | null)[]
  | null;

export const sendFieldInput = (item?: InputFieldType): Array<FieldInput> | undefined => {
  if (item && Array.isArray(item) && item.length) {
    // @ts-ignore: Ignore error, works correctly.
    return item?.filter((field) => !(!field?.name || (!field?.value && field?.value !== 0)));
  }

  return [];
};

export const getFieldInputOrDefault = (item?: InputFieldType): Array<FieldInput> => {
  if (item && item.length) {
    return item?.map((field) => ({
      name: field?.name || '',
      value: field?.value || '',
    }));
  }

  return [{ name: '', value: '' }];
};

type InputCapacityType =
  | ({
      capacity: number | null;
    } | null)[]
  | null;

export const sendCapacityInput = (item?: InputCapacityType): Array<CapacityInput> | undefined => {
  if (item && Array.isArray(item) && item.length) {
    // @ts-ignore: Ignore error, works correctly.
    return item?.filter((field) => !!field?.capacity || field?.capacity === 0);
  }

  return undefined;
};

export const getCapacityInputOrDefault = (item?: InputCapacityType): Array<{ capacity: number | null }> => {
  if (item && item.length) {
    return item?.map((field) => ({
      capacity: field?.capacity || null,
    }));
  }

  return [{ capacity: null }];
};

export const engineInputDefaultValues = {
  drive: undefined,
  fuel: undefined,
  maker: '',
  model: '',
  power: '',
  power_type: 'hp',
  type: undefined,
  website: '',
};

type InputEngineType =
  | ({
      maker?: string | null;
      website?: string | null;
      model?: string | null;
      type?: string | null;
      drive?: string | null;
      fuel?: string | null;
      power?: string | null;
      power_type?: string | null;
    } | null)[]
  | null;

export const sendEngineInput = (item?: InputEngineType): Array<EngineInput> | undefined => {
  if (item && Array.isArray(item) && item.length) {
    // @ts-ignore: Ignore error, works correctly.
    return item?.filter((engine) => engine !== null && !!engine?.power_type);
  }

  return undefined;
};

export const getEngineInputOrDefault = (item?: InputEngineType): Array<EngineInput> => {
  if (item && item.length) {
    return item?.map((field) => ({
      ...field,
      power_type: field?.power_type ? field?.power_type : 'hp',
    }));
  }

  // Default values
  return [engineInputDefaultValues];
};

/** CE Classification */

export const sendCEClassification = (item?: (string | null)[] | null): (number | null)[] | undefined => {
  if (item && Array.isArray(item) && item.length) {
    return item.map((ce) => {
      if (ce) {
        return parseInt(ce, 10);
      }

      return null;
    });
  }

  return undefined;
};

/** Maker */

export const getSelectedMaker = (makersData?: GetMakers, selectedMaker?: string | null): string | null | undefined => {
  if (makersData?.manufacturers?.data?.length && selectedMaker) {
    return makersData?.manufacturers?.data.find((e) => e.name === selectedMaker)?.id;
  }

  return undefined;
};

/** Categories/Activities */

type GetInputIds =
  | ({
      name?: string | null;
      id: string;
    } | null)[]
  | null;

export const getStringIds = (items?: GetInputIds): string[] | undefined => {
  if (items && Array.isArray(items) && items.length) {
    return items.map((item) => item?.id!);
  }

  return undefined;
};

export const sendArrayOfNumbers = (items?: string[]): number[] | undefined => {
  if (items && Array.isArray(items) && items.length) {
    return items.map((item) => parseInt(item!, 10));
  }

  return undefined;
};

/** Files */

export const sendFileIds = (files?: GetBoatDetailsData['files']): number[] | null | undefined => {
  if (files && Array.isArray(files) && files.length) {
    return files?.filter((file) => !!file?.id)?.map((file) => parseInt(file?.id!, 10));
  }

  return undefined;
};

type FiledInput = InputMaybe<FieldInput[]> | undefined;

export const getCustomFields = (fields?: EditableCustomFields[]): FiledInput => {
  if (fields && fields?.length) {
    return fields.map((item) => {
      if (item) {
        if (item.type === 'date') {
          return { name: item?.name || '', value: momentToDate(item?.value) || '' };
        }

        return { name: item?.name || '', value: item?.value?.toString() || '' };
      }

      return { name: '', value: '' };
    });
  }

  return [];
};

/** String/Number or Undefined */

export const toFloatOrUndefined = (field?: string | null) => {
  if (field) {
    return parseFloat(field);
  }

  return undefined;
};

export const toIntOrUndefined = (field?: string | null) => {
  if (field) {
    return parseInt(field, 10);
  }

  return undefined;
};

// Utils for People Pickers
export type LabelInValue = {
  label: string | JSX.Element | (string | JSX.Element)[];
  value: string;
};

type MembersLabelInValueReturnType = LabelInValue[] | undefined;

type MembersLabelInValueItems = NonNullable<GetTripDetails['trip']>['members'];

export const getMembersLabelInValue = (items?: MembersLabelInValueItems): MembersLabelInValueReturnType => {
  if (!!items && items?.length) {
    return items.map((item) => ({
      label: item?.display_name || '',
      value: item?.email || '',
    }));
  }

  return undefined;
};

// Utils for Location Picker

export const getLocationValue = (
  destination_latitude: number | null | undefined,
  destination_longitude: number | null | undefined,
) => {
  if (destination_latitude && destination_longitude) {
    return {
      lat: destination_latitude,
      lng: destination_longitude,
    };
  }

  return undefined;
};
